import {useSkilQuery} from '../Utilities/QueryClient'
import * as React from 'react'

export const RegionsExport = hasLoaded => {
    const countiesQueryResult = useSkilQuery<'getCountyCollection'>(`/api/counties`, {pagination: false}, {enabled: hasLoaded})
    return React.useMemo(() => {
        const countiesRaw = countiesQueryResult.data?.['hydra:member'] ?? []
        const regionsTemp = []
        return Object.values(countiesRaw)
            .map(county => {
                // @ts-expect-error
                if (!regionsTemp.includes(county.regionId)) {
                    // @ts-expect-error
                    regionsTemp.push(county.regionId)
                    return {value: county.regionId, label: county.region}
                }
            })
            .filter(region => region !== undefined)
            .sort(function (a, b) {
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })
    }, [countiesQueryResult])
}

import * as React from 'react'
import {SkilQueryResponseType, SkilRequestBodyType, useSkilQuery} from '../../Utilities/QueryClient'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '../../Components/Fields/TextField'
import Modal from '../../Components/Modal'
import LoadingComponent from '../../Components/LoadingComponent'
import {CountyAutocomplete} from '../../Components/CountyAutocomplete'
import {components} from '../../Generated/eportal'
import {Chip} from '@mui/material'

export type FindDoctorModalDto = SkilRequestBodyType<'createPraksisnettDoctor'>
type PartialDto = Partial<FindDoctorModalDto>
const validateEmail = email => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email)

type NewDoctorType = NonNullable<FindDoctorModalDto['newDoctor']>
type HelfoDoctorType = NonNullable<FindDoctorModalDto['helfoDoctor']>
type PraksisNettDoctorType = NonNullable<FindDoctorModalDto['doctor']>

type OfficeSelectionType = Pick<NewDoctorType, 'office' | 'helfoOffice' | 'newOffice'> | Pick<HelfoDoctorType, 'helfoOffice' | 'newOffice'>
type PraksisNettDoctorResponseType = SkilQueryResponseType<'getPraksisnettDoctorCollection'>['hydra:member'][number]
type HelfoDoctorResponseType = SkilQueryResponseType<'getHpnDoctorCollection'>['hydra:member'][number]

type Props = {
    onSave?: (user: FindDoctorModalDto) => Promise<any> | any
    onClose: () => void
    saveLabel?: string
    description?: string
    initSearch?: string
    children?: React.ReactNode
}

function validateNewDoctor(newDoctor: PartialDto['newDoctor']) {
    if (!newDoctor) {
        return false
    }

    if (!newDoctor.name || !newDoctor.email || !validateEmail(newDoctor.email)) {
        return false
    }

    if (newDoctor.office) {
        return newDoctor.office.startsWith('/api/praksisnett/offices')
    } else if (newDoctor.helfoOffice) {
        return newDoctor.helfoOffice.startsWith('/api/helfo_offices')
    } else if (newDoctor.newOffice) {
        if (!newDoctor.newOffice.name || !newDoctor.newOffice.county) {
            return false
        }
        return newDoctor.newOffice.county.startsWith('/api/counties')
    } else {
        return false
    }
}

export default function FindDoctorModal({
    onSave,
    onClose,
    saveLabel = '',
    description = undefined,
    initSearch = '',
    children = undefined,
}: Props) {
    const [search, setSearch] = React.useState(initSearch)
    const [selected, setSelected] = React.useState<PartialDto>({})
    const skilQuery = /\S+@\S+\.\S+/.test(search) ? `email=${search}` : `name=${search}`
    const praksisNettDoctorsResponse = useSkilQuery<'getPraksisnettDoctorCollection'>(
        `/api/praksisnett/doctors?${skilQuery}&itemsPerPage=5`,
        null,
        {
            enabled: search.length > 3,
        }
    )
    const doctors = praksisNettDoctorsResponse?.data?.['hydra:member'] ?? []
    const helfoUsersResponse = useSkilQuery<'getHpnDoctorCollection'>(`/api/helfo_doctors?name=${search}&itemsPerPage=5`, null, {
        enabled: search.length > 3,
    })
    const helfoUsers = helfoUsersResponse?.data?.['hydra:member'] ?? []
    const onSubmit = async () => {
        if (!selected || !onSave) {
            return
        }

        return onSave(selected)
    }

    let saveText = 'Opprett lege'
    if (selected.helfoDoctor) {
        saveText = 'Legg til Helfo-lege'
    }
    if (selected.doctor) {
        saveText = 'Legg til PraksisNett lege'
    }
    if (saveLabel) {
        saveText = saveLabel
    }
    //
    const doctorValid = selected.doctor && selected.doctor.startsWith('/api/praksisnett/doctors')
    const helfoDoctorValid =
        selected.helfoDoctor &&
        selected.helfoDoctor.doctor.startsWith('/api/helfo_doctors') &&
        selected.helfoDoctor?.helfoOffice?.startsWith('/api/helfo_offices') &&
        validateEmail(selected.helfoDoctor.email)

    const newDoctorValid = validateNewDoctor(selected.newDoctor)

    const userValid = doctorValid || helfoDoctorValid || newDoctorValid

    const missingHelfoUsers = helfoUsers.filter(h => !doctors.find(u => u.helfoDoctor === h['@id']))
    return (
        <Modal
            title={`Legg til eller lag ny bruker`}
            onSave={userValid && onSave ? onSubmit : undefined}
            onClose={onClose}
            saveText={saveText}
        >
            {description}
            <TextField id={'search'} label={'Søk'} placeholder={'Søk etter navn eller epost'} value={search} onChange={setSearch} />
            <div className={'list-group'}>
                {missingHelfoUsers.map(u => (
                    <HelfoItem key={'helfo' + u.id} selection={selected} onSelect={setSelected} doctor={u} />
                ))}
                {(helfoUsersResponse.isLoading || praksisNettDoctorsResponse.isLoading) && <LoadingComponent />}
                <NewItem selection={selected} onSelect={setSelected} />
            </div>
            {children}
        </Modal>
    )
}

/*******************
 * PraksisNett Doctor Item!
 *******************/

type DoctorItemProps = {
    selection: PartialDto
    onSelect: (selection: {doctor: PraksisNettDoctorType; newDoctor: null; helfoDoctor: null}) => void
    doctor: PraksisNettDoctorResponseType
}

const DoctorItem = ({selection, onSelect, doctor}: DoctorItemProps) => {
    const isSelected = doctor['@id'] === selection.doctor

    if (!isSelected) {
        return (
            <button onClick={() => onSelect({doctor: doctor['@id']!, newDoctor: null, helfoDoctor: null})} className={'list-group-item'}>
                {doctor.name} ({doctor.office?.name ?? 'Ingen legekontor'})
                <Chip size='small' label='PraksisNett' color='primary' className='pull-right' />
                {doctor.helfoDoctor && <Chip size='small' className='pull-right' label={'HELFO'} />}
            </button>
        )
    }

    return (
        <div className={'list-group-item'}>
            <h5>
                <i className={'fa fa-circle'} /> {doctor.name} ({doctor.office?.name ?? 'Ingen legekontor'})
                <Chip size='small' label='PraksisNett' color='primary' className='pull-right' />
                {doctor.helfoDoctor && <Chip size='small' className='pull-right' label={'HELFO'} />}
            </h5>
        </div>
    )
}

/*******************
 * New User Item!
 *******************/
type NewItemProps = {
    selection: PartialDto
    onSelect: (selection: {doctor: null; newDoctor: NewDoctorType; helfoDoctor: null}) => void
}
const NewItem = ({selection, onSelect}: NewItemProps) => {
    const onLocalSelect = (name: string, email: string, county: string | null, district: string | null) => {
        onSelect({
            // @ts-expect-error county is never null here
            newDoctor: {...selection.newDoctor, name, email, county, district},
            helfoDoctor: null,
            doctor: null,
        })
    }

    const onChangeOffice = (office: OfficeSelectionType) => {
        // @ts-expect-error new doctor is okay
        onSelect({...selection, newDoctor: {...selection.newDoctor, ...office}})
    }

    if (!selection.newDoctor) {
        return (
            <button
                style={{background: '#052a30', color: 'white'}}
                onClick={() => onLocalSelect('', '', null, null)}
                className={'list-group-item'}
            >
                Opprett ny bruker
            </button>
        )
    }

    return (
        <div className={'list-group-item'}>
            <h5>
                <i className={'fa fa-circle'} /> Opprett ny bruker
            </h5>
            <TextField
                label={'Navn'}
                value={selection.newDoctor?.name ?? ''}
                onChange={name =>
                    onLocalSelect(
                        name,
                        selection.newDoctor?.email ?? '',
                        selection.newDoctor?.county ?? null,
                        selection.newDoctor?.district ?? null
                    )
                }
                id={'name'}
            />
            <TextField
                id={'email'}
                label={'Epost'}
                value={selection.newDoctor?.email ?? ''}
                validateFn={validateEmail}
                onChange={email =>
                    onLocalSelect(
                        selection.newDoctor?.name ?? '',
                        email,
                        selection.newDoctor?.county ?? null,
                        selection.newDoctor?.district ?? null
                    )
                }
            />
            <CountyAutocomplete
                sx={{mb: 2}}
                value={selection.newDoctor?.county ?? null}
                valueDistrict={selection.newDoctor?.district ?? undefined}
                onChange={(e, c, iri, district) => {
                    const districtIri = district?.id ?? null
                    onLocalSelect(selection.newDoctor?.name ?? '', selection.newDoctor?.email ?? '', iri, districtIri)
                }}
            />
            {selection.newDoctor?.county ? (
                <OfficeItem
                    officeSelection={selection.newDoctor}
                    county={selection.newDoctor.county}
                    district={selection.newDoctor.district ?? undefined}
                    onChange={onChangeOffice}
                />
            ) : (
                <span className={'help-block'}>Velg kommune</span>
            )}
        </div>
    )
}

/*******************
 * Helfo Item!
 *******************/
type HelfoItemProps = {
    selection: PartialDto
    onSelect: (selection: {helfoDoctor: HelfoDoctorType; newDoctor: null; doctor: null}) => void
    doctor: HelfoDoctorResponseType
}
type HelfoOfficeType = components['schemas']['HpnOffice.jsonld-Doctor.user']
const HelfoItem = ({selection, onSelect, doctor}: HelfoItemProps) => {
    const isSelected = selection.helfoDoctor?.doctor === doctor['@id']

    if (!isSelected) {
        // @ts-expect-error doctors will always have atleast 1 office, and the type relation is wrong
        const firstOffice: HelfoOfficeType = doctor.offices[0]
        return (
            <button
                className={'list-group-item'}
                onClick={() =>
                    onSelect({
                        helfoDoctor: {
                            doctor: doctor['@id']!,
                            helfoOffice: firstOffice['@id'],
                            email: '',
                            county: null,
                        },
                        doctor: null,
                        newDoctor: null,
                    })
                }
            >
                <>
                    {doctor.name} ({firstOffice.name}) <Chip size='small' className='pull-right' label={'HELFO'} />
                </>
            </button>
        )
    }

    const onChangeEmail = email => {
        onSelect({
            ...selection,
            // @ts-expect-error the object is okay here
            helfoDoctor: {
                ...selection.helfoDoctor,
                email,
            },
        })
    }

    const onLocalSelectOffice = (office: string) => {
        onSelect({
            ...selection,
            // @ts-expect-error the object is okay here
            helfoDoctor: {
                ...selection.helfoDoctor,
                helfoOffice: office,
            },
        })
    }

    return (
        <div className={'list-group-item'}>
            <h5>
                <i className={'fa fa-circle'} />
                {doctor.name} ({doctor.offices[0]?.hpnOffice?.name ?? 'Ingen legekontor'})
                <Chip size='small' className='pull-right' label={'HELFO'} />
            </h5>

            <TextField
                id={'email'}
                label={'Epost'}
                type={'text'}
                value={selection.helfoDoctor!.email}
                validateFn={validateEmail}
                onChange={onChangeEmail}
            />
            <div className={'list-group'}>
                {doctor.offices.map(relation => {
                    // @ts-expect-error Relation is of wrong type
                    const office: HelfoOfficeType = relation
                    const isSelectedOffice = office['@id'] === selection.helfoDoctor!.helfoOffice

                    return (
                        <button
                            onClick={() => onLocalSelectOffice(office['@id']!)}
                            className={'list-group-item'}
                            key={'office' + office.id}
                        >
                            {isSelectedOffice && <i className={'fa fa-circle'} />} {office.name}
                        </button>
                    )
                })}
            </div>
        </div>
    )
}

/*******************
 * Office Item!
 *******************/

type OfficeItemProps = {
    officeSelection: OfficeSelectionType
    county: string
    district?: string
    onChange: (OfficeSelectionType) => void
}

const OfficeItem = ({officeSelection, onChange, county, district}: OfficeItemProps) => {
    const officesResponse = useSkilQuery<'getPraksisNettOffices'>(
        `/api/praksisnett/offices`,
        {county, pagination: false},
        {enabled: !!county}
    )

    const externalOfficesResponse = useSkilQuery<'getCountyExternalOfficeCollection'>(
        `${county}/external_offices`,
        {pagination: false},
        {
            enabled: !!county,
        }
    )
    const helfoOffices = externalOfficesResponse.data?.['hydra:member'] ?? []
    const praksisNettOffices = officesResponse.data?.['hydra:member'] ?? []

    const loading = officesResponse.isLoading || externalOfficesResponse.isLoading

    const options: {value: string | null; label: string; type: string}[] = praksisNettOffices.map(o => {
        return {
            label: o.name,
            value: o['@id']!,
            type: o.helfoOffice ? 'both' : 'praksisNett',
        }
    })

    helfoOffices.forEach(h => {
        const include = !praksisNettOffices.find(s => s.helfoOffice === h['@id'])
        if (!include) return

        options.push({
            label: h.name,
            value: h['@id']!,
            type: 'helfo',
        })
    })

    options.unshift({
        label: 'Lag nytt legekontor',
        value: 'new',
        type: 'new',
    })

    const onLocalChange = (event, option) => {
        if (option.value == null) {
            return
        }

        if (option.value === 'new') {
            onChange({newOffice: {name: '', county: county, district: district}, office: null, helfoOffice: null})
        } else if (option.value.startsWith('/api/praksisnett/offices')) {
            const hasDistrict = praksisNettOffices.find(p => p['@id'] === option.value)?.district
            onChange({office: option.value, newOffice: null, helfoOffice: null, district: hasDistrict?.['@id'] ?? null})
        } else {
            onChange({helfoOffice: option.value, newOffice: null, office: null})
        }
    }

    const onChangeName = (name: string) => {
        onChange({...officeSelection, newOffice: {...officeSelection.newOffice, name}})
    }

    const selectedOffice =
        officeSelection.office ?? officeSelection.helfoOffice ?? (typeof officeSelection.newOffice?.name === 'string' ? 'new' : null)
    const selectedOption = options.find(o => o.value === selectedOffice) || null

    return (
        <>
            <Autocomplete
                sx={{mb: 2}}
                id={'choose-office'}
                fullWidth
                //@ts-expect-error no idea why this is complaining
                value={selectedOption}
                disabled={!county}
                options={options}
                onChange={onLocalChange}
                placeholder={'Velg...'}
                disableClearable
                renderOption={(props, option) => {
                    const type = option.type

                    return (
                        <li {...props}>
                            <div style={{width: '100%'}}>
                                <span className='pull-left'>{option.label}</span>
                                <span className='pull-right'>
                                    {(type === 'both' || type === 'praksisNett') && (
                                        <Chip size='small' label='PraksisNett' color='primary' className='pull-right' />
                                    )}
                                    {(type === 'both' || type === 'helfo') && <Chip size='small' className='pull-right' label={'HELFO'} />}
                                    {type === 'new' && <strong>{option.label}</strong>}
                                </span>
                            </div>
                            <br />
                        </li>
                    )
                }}
                renderInput={params => (
                    <TextField
                        required={true}
                        {...params}
                        label={'Velg arbeidssted'}
                        // @ts-expect-error
                        helperText={county ? '' : 'Du må velge kommune først'}
                    />
                )}
            />
            {loading && county && <LoadingComponent />}
            {selectedOption?.value === 'new' && (
                <TextField
                    id={'office-name'}
                    label={'Legekontor navn'}
                    value={officeSelection.newOffice?.name ?? ''}
                    onChange={onChangeName}
                />
            )}
        </>
    )
}
